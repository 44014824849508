import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

class TermsOfUse extends React.Component {

	componentDidMount() {
		document.getElementsByTagName('html')[0].classList.add('seo-page');
	}

	componentWillUnmount() {
		document.getElementsByTagName('html')[0].className = "";
	}

	render() {
		return (
			<Layout>
				<SEO title="Terms of use | Writera Limited" />
				<Header />

				<main className="main terms-of-use">
					<h1>Terms of use</h1>

					<p>Writera Limited (herein referred to as “Writera”, or “we”, “us”, “our”), a legal entity under the laws of Cyprus, maintains this website writera.ltd (the “Website”) as its landing page for business and presentation of its services. Access to and use of this Website are subject to the terms and conditions (the “Terms” hereinafter) set forth, and all applicable laws. Writera may revise and modify the existing Terms, introduce changes to them, or add new content to any element of these Terms from time to time by updating this statement without giving you notice on such. You may browse and use this Website for your own personal, non-commercial use with the purpose of information access and communication with Writera.</p>
					<p>WARNING: PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE. ACCESSING AND USING THIS WEBSITE INDICATES THAT YOU: (1) ACCEPT THESE TERMS, INCLUDING <Link to="/privacy-policy/">PRIVACY POLICY</Link>, (2) AGREE TO THESE TERMS, AND (3) AGREE TO BE LEGALLY BOUND BY THESE TERMS AS AMENDED FROM TIME TO TIME. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE AND DO NOT ACCESS THIS WEBSITE.</p>

					<h2>COPYRIGHT AND TRADEMARKS</h2>

					<h3>Copyright Notice</h3>
					<p>All items of the Website’s content are protected by copyright, and are owned by us, or licensed to us by our suppliers, contractors, affiliates and/or partners. All rights reserved. You agree to comply with all copyright notices, trademark rules, information, and restrictions contained herein and in any items of content, which you may find on the Website.</p>
					<p>While Writera maintains copyright protection, and/or exercises licensing rights in all publications and information it places on the Website, Writera consents to normal downloading, copying and distribution of the information for non-commercial purposes within the user’s company/organization only. In consideration for this, users agree that copies of the information will retain all copyright and other proprietary notices. Writera does not intend, and such shall not be deemed in any context, to transfer the intellectual property rights for the Website (or any part thereof) to any person who accesses the Website.</p>

					<h3>Trademarks and Service marks owned by Writera</h3>
					<p>The Writera logo and product names referenced herein are either registered trademarks of Writera or protected by copyright. All other brands and product names mentioned herein are trademarks of their respective owners.</p>

					<h3>Contacting us</h3>
					<p>If you have any questions or comments about our copyright and trademarks statement or practices, please contact us via email at <a href="mailto:contact@writera.ltd">contact@writera.ltd</a>. Writera reserves the right to modify or update this copyright notice without prior notification.</p>

					<h2>LEGAL TERMS</h2>
					<p>This Website may contain other proprietary notices and information on copyright, the terms of which must be observed, followed and hold up to. Information on this Website may contain typographical errors or technical inaccuracies. Information may be changed or updated without prior notification. Writera may also make improvements and/or changes in the products, and/or the services, and/or the programs described in this information at any time without prior notification. Please note, that Writera does not want to receive confidential or proprietary information from you through our Website.</p>

					<h3>Termination and Suspension of Use</h3>
					<p>You may access the Website solely for your own personal, non-commercial use with the purpose of information access and communication with us on any type of inquiry.</p>
					<p>You must not use any part of the Website for any illegal purpose. In particular, you may not use the Website for any of the following goals:</p>

					<ul>
						<li>disseminating any unlawful, harassing, defamatory, abusive, threatening, harmful, obscene, or otherwise objectionable, or otherwise breaching any laws, materials;</li>
						<li>transmitting materials that encourages conduct, which constitutes criminal offense, or otherwise breaches any applicable laws, regulations or code(s) of practice;</li>
						<li>interfering with any other person’s use of the Website; or</li>
						<li>making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner (whoever he/she may be).</li>
					</ul>

					<p>If you breach any of the provisions of these Terms, you will be responsible for any losses and costs resulting from your breach.</p>
					<p>Writera reserves the right to terminate your use of the Website if you breach any of these Terms.</p>
					<p>If we reasonably suspect your use of the Website is affecting the lawful operation of the Website, or may adversely affect other persons, Writera may suspend your access to the Website at any time while we investigate the matter.</p>
					<p>If necessary, Writera may at any time suspend access to the Website, or close it indefinitely. Writera will not be liable if for any reason the Website is unavailable at any time or for any period.</p>

					<h2>LIMITATION OF LIABILITY</h2>
					<p>Writera’s obligations with respect to the products and/or services, described or mentioned in the information placed on this Website, are governed solely by the agreements under which such information and/or the products and/or services are provided.</p>
					<p>If you obtain any information, a product or service from Writera via this Website, which is provided without an agreement, then such information, product or service is provided “as is” with no warranties whatsoever, express or implied, and your use of such information, product or service is at your own risk. In addition, a link to a non-Writera Website does not mean that Writera endorses or accepts any responsibility for the content, or the use, of such Website. It is up to you to take precautions to ensure that whatever you select for your use is free of such items as viruses, worms, trojan horses and other items of a destructive nature.</p>
					<p>In no event will Writera be liable to you or  any other person, whether arising out of or in connection with information this Website contains and/or a products and/or service described in this Website, including but not limited to:</p>

					<ul>
						<li>phone, electronic, hardware or software, network, Internet, email, or computer malfunctions, failures or difficulties of any kind;</li>
						<li>failed, incomplete, garbled or delayed computer and/or email transmissions;</li>
						<li>any condition caused by events beyond the control of Writera, which may cause the services to be (as applicable) delayed, disrupted, or corrupted;</li>
						<li>any injuries, losses or damages of any kind arising in connection with, or as a result of, utilizing the services.</li>
					</ul>

					<p>In no event will Writera be liable to you or any other person, whether arising out of or in connection with your use of the Website, or your inability to use the Website, or for any other reason including, without limitation, for:</p>

					<ul>
						<li>any matters due to any events outside our reasonable control; or</li>
						<li>any unforeseeable losses or damages.</li>
					</ul>

					<p>WARNING: TO AVOID ANY DOUBTS PLEASE BE AWARE THAT IN NO EVENT WILL WRITERA BE LIABLE TO YOU OR OTHER ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE OF THIS WEBSITE, OR ON ANY OTHER HYPER LINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

					<h2>CONTACTING US</h2>
					<p>If you require further information about the Website use or have any questions or comments about our Terms, please contact us via email at <a href="mailto:contact@writera.ltd">contact@writera.ltd</a>. As stated above, Writera reserves the right to modify or update these Terms at any time without notice.</p>

					<p><em>Published on: February 14, 2020</em></p>
				</main>

				<Footer />
			</Layout>
		)
	}
}

export default TermsOfUse
